import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { environment } from "../../../environment.js";
import Swal from "sweetalert2";
import Header from "../../../components/Header";
import { jwtDecode } from "jwt-decode";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Button, Select, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { JobStates } from "../../../enum.js";

const Jobs = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedStaff, setSelectedStaff] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [staff, setStaff] = useState([]);
  const [clients, setClients] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);
  const [staffPayment, setStaffPayment] = useState(0);
  const [profit, setProfit] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const fetchAllRef = useRef(false); // Track if "view all" is clicked
  const token = localStorage.getItem("token");

  
  // Format date to the required format
  const formatTime = (dateTime) => {
    if (!dateTime) return null;
    const time = new Date(dateTime);
    return time.toLocaleTimeString("en-CA", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };
  
  // Get user ID from token
  const getUserIdFromToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return decodedToken._id;
      } catch (error) {
        console.error("Error decoding token:", error);
        return null;
      }
    }
    return null;
  };

  // Get user role from token
  const getUserRoleFromToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return decodedToken.role;
      } catch (error) {
        console.error("Error decoding token:", error);
        return null;
      }
    }
    return null;
  };

  const userRole = getUserRoleFromToken();
  const userId = getUserIdFromToken();

  // Fetch Jobs based on filters
  const fetchJobs = async () => {
    try {
      if (fetchAllRef.current) {
        return;
      }
  
      let url;
      if (userRole === "staff") {
        url = environment.apiUrl + `/job/getJobsbyStaff/${userId}`;
      } else {
        url = environment.apiUrl + "/job/getAllJobs";
      }
  
      let params = new URLSearchParams();
      if (startDate) params.append("startDate", startDate);
      if (endDate) params.append("endDate", endDate);
      if (selectedStaff) params.append("staffId", selectedStaff);
      if (selectedClient) params.append("clientId", selectedClient);
  
      if (fetchAllRef.current) {
        params.delete("startDate");
        params.delete("endDate");
        params.delete("staffId");
        params.delete("clientId");
      }
  
      const response = await axios.get(url, { params });
      const responseData = response.data;
  
      if (responseData.success) {
        // Filter out jobs with "Cancelled" status
        const filteredJobs = responseData.jobs.filter((job) => job.jobStatus !== "Cancelled");
  
        const modifiedData = filteredJobs.map((item) => ({
          ...item,
          id: item._id,
          jobDate: formatTime(item.jobDate),
        }));
  
        modifiedData.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  
        // Only update state if data has changed to prevent unnecessary re-renders
        if (JSON.stringify(modifiedData) !== JSON.stringify(data)) {
          setData(modifiedData);
          calculateSummary(modifiedData);
        }
      } else {
        console.error("Failed to fetch jobs:", responseData.message);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
      if (error.response?.data?.message === "Jobs not found") {
        setData([]);
        calculateSummary([]);
      }
      setData([]);
    }
  };
  

  // Calculate total, staff payments, and profit
  const calculateSummary = (jobs) => {
    // Filter out cancelled jobs from the calculation
    const filteredJobs = jobs.filter((job) => job.jobStatus !== "Cancelled");
  
    const totalPayment = filteredJobs.reduce(
      (acc, job) => acc + (job.orgTotal || 0),
      0
    );
    const staffPayment = filteredJobs.reduce(
      (acc, job) => acc + (job.staffPayTotal || 0),
      0
    );
    const profit = totalPayment - staffPayment;
    setTotalPayment(totalPayment);
    setStaffPayment(staffPayment);
    setProfit(profit);
  };
  

  // Fetch Staff and Clients
  const fetchStaffAndClients = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const [staffResponse, clientResponse] = await Promise.all([
        axios.get(`${environment.apiUrl}/staff/getAllStaff`, { headers }),
        axios.get(`${environment.apiUrl}/client/getAllClient`, { headers }),
      ]);

      const staffData = staffResponse.data.staffs.map((item) => ({
        ...item,
        id: item._id,
      }));
      const clientData = clientResponse.data.clients.map((item) => ({
        ...item,
        id: item._id,
      }));

      setStaff(staffData);
      setClients(clientData);
    } catch (error) {
      console.error("Error fetching staff or clients:", error);
    }
  };

  // Handle view all button click
  const vievAlljobs = () => {
    // fetchAllRef.current = true;
    setEndDate("");
    setStartDate("");
    setSelectedClient("");
    setSelectedStaff("");

    console.log("view all jobs");
    fetchJobs();
  };

  // Handle filter change and fetch jobs
  const handleViewJobs = () => {
    fetchJobs();
  };

  useEffect(() => {
    fetchJobs();
    if (userRole !== "staff") {
      fetchStaffAndClients();
    }
  }, [startDate, endDate, selectedStaff, selectedClient]);

  // Handle Status change
  const handleStatusChange = async (id, newStatus) => {
    try {
      const response = await axios.patch(
        environment.apiUrl + `/job/updateStatus/${id}`,
        { jobStatus: newStatus }
      );
      if (response.data.success) {
        setData((prevData) =>
          prevData.map((item) =>
            item.id === id ? { ...item, jobStatus: newStatus } : item
          )
        );
        Swal.fire("Updated!", "Job status has been updated.", "success");
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating job status:", error);
      Swal.fire(
        "Error!",
        "Failed to update job status. Please try again later.",
        "error"
      );
    }
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="-10px"
      >
        <Box flex={1}>
          <Header title="Jobs Payment Summary" subtitle="Managing the Payments" />
        </Box>

        <Box flex={1}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            marginBottom="20px"
            gap="10px"
          >
            <Box>
              <Typography fontWeight="bold" fontSize="16px">
                From
              </Typography>
              <TextField
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                fullWidth
              />
            </Box>

            <Box>
              <Typography fontWeight="bold" fontSize="16px">
                To
              </Typography>
              <TextField
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                fullWidth
              />
            </Box>

            {userRole !== "staff" && (
              <Box>
                <Typography fontWeight="bold" fontSize="16px">
                  Staff
                </Typography>
                <Select
                    fullWidth
                    style={{ minWidth: "100px" }}
                  value={selectedStaff}
                  onChange={(e) => setSelectedStaff(e.target.value)}
                >
                  {staff.map((staff) => (
                    <MenuItem key={staff.id} value={staff.id}>
                      {staff.firstName} {staff.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}

            <Box>
              <Typography fontWeight="bold" fontSize="16px">
                Client
              </Typography>
              <Select
                    fullWidth
                    style={{ minWidth: "100px" }}
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.firstName} {client.lastName}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Button
              variant="contained"
              onClick={handleViewJobs}
              sx={{
                backgroundColor: "#4caf50",
                color: "white",
                whiteSpace: "nowrap",
                fontSize: "12px",
              }}
              disabled={(!startDate && endDate) || (startDate && !endDate)}
            >
              Filter Jobs
            </Button>

            <Button
              variant="contained"
              onClick={vievAlljobs}
              sx={{
                backgroundColor: "#4caf50",
                color: "white",
                whiteSpace: "nowrap",
                fontSize: "12px",
              }}
            >
              View All
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="62vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Typography variant="h4" gutterBottom>
          Total Income: ${totalPayment}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Staff Payment: ${staffPayment}
        </Typography>
        <Typography variant="h4" gutterBottom>
          Profit: ${profit}
        </Typography>
        <DataGrid
          rows={data}
          columns={[
            { field: "id", headerName: "Job ID", hide: true },
            { field: "jobName", headerName: "Job Name", flex: 0.8 },
            {
              field: "client",
              headerName: "Client",
              flex: 0.7,
              renderCell: (params) =>
                `${params.row.client.firstName} ${params.row.client.lastName}`,
            },
            {
              field: "assignedStaff",
              headerName: "Assigned Staff",
              flex: 0.8,
              renderCell: (params) => {
                const { assignedStaff } = params.row;
                return assignedStaff
                  ? `${assignedStaff.firstName} ${assignedStaff.lastName}`
                  : ""; // Fallback text if assignedStaff is null or undefined
              },
            },
            {
              field: "jobDate",
              headerName: "Job Date",
              flex: 0.7,
            },
            {
              field: "orgTotal",
              headerName: "Client Payment",
              flex: 0.5,
            },
            { field: "staffPayTotal", headerName: "Staff Payment", flex: 0.5 },
            {
              field: "jobStatus",
              headerName: "Status",
              flex: 0.6,
              renderCell: (params) => {
                return (
                  <Select
                    value={params.row.jobStatus}
                    onChange={(e) => handleStatusChange(params.row.id, e.target.value)}
                  >
                    {Object.values(JobStates).map((status) => (
                      <MenuItem key={status} value={status}>
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                );
              },
            },
          ]}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Jobs;
