import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  Snackbar,
  Alert as MuiAlert,
  AlertTitle,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormHelperText,
} from "@mui/material";
import Header from "./Header";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { environment } from "../environment";

const AddNoteDialog = ({ open, handleClose, handleAddNote }) => {
  const [clients, setClients] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    fetchClients();
    fetchStaffs();
  }, []);

  const token = localStorage.getItem("token");

  const fetchClients = async () => {
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(
        environment.apiUrl + "/client/getAllActiveClient",
        { headers }
      );

      if (response.data.success) {
        setClients(response.data.clients || []); // Ensure it's a valid array
      } else {
        showErrorSnackbar("Failed to fetch clients");
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      showErrorSnackbar("Failed to fetch clients");
    }
  };

  const fetchStaffs = async () => {
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(
        environment.apiUrl + "/staff/getAllActiveStaff",
        { headers }
      );

      if (response.data.success) {
        setStaffs(response.data.staffs || []); // Ensure it's a valid array
      } else {
        showErrorSnackbar("Failed to fetch staffs");
      }
    } catch (error) {
      console.error("Error fetching staffs:", error);
      showErrorSnackbar("Failed to fetch staffs");
    }
  };

  const showErrorSnackbar = (message) => {
    setAlertSeverity("error");
    setAlertMessage(message);
    setOpenSnackbar(true);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Header title="Add a New Note" subtitle="" />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            onClose={() => setOpenSnackbar(false)}
            severity={alertSeverity}
            elevation={6}
            variant="filled"
            sx={{ color: "#fff" }}
          >
            <AlertTitle>
              {alertSeverity === "success" ? "Success" : "Error"}
            </AlertTitle>
            {alertMessage}
          </MuiAlert>
        </Snackbar>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={noteSchema}
          onSubmit={(values, { resetForm }) => {
            handleAddNote(values);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gap="20px">
                <Typography fontWeight="bold" fontSize="16px">
                  Title*
                </Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  name="title"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  error={!!touched.title && !!errors.title}
                  helperText={touched.title && errors.title}
                />

                <Typography fontWeight="bold" fontSize="16px">
                  Description*
                </Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  name="description"
                  multiline
                  rows={3}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />

                <Typography fontWeight="bold" fontSize="16px">
                  Client
                </Typography>
                <FormControl fullWidth variant="filled">
                  <Select
                    name="client"
                    value={values.client}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <MenuItem value="">None</MenuItem> {/* Allow unselecting */}
                    {clients?.map((client) => (
                      <MenuItem key={client?._id} value={client?._id}>
                        {client?.firstName} {client?.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched.client && errors.client}
                  </FormHelperText>
                </FormControl>

                <Typography fontWeight="bold" fontSize="16px">
                  Staff
                </Typography>
                <FormControl fullWidth variant="filled">
                  <Select
                    name="staff"
                    value={values.staff}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <MenuItem value="">None</MenuItem> {/* Allow unselecting */}
                    {staffs?.map((staff) => (
                      <MenuItem key={staff?._id} value={staff?._id}>
                        {staff?.firstName} {staff?.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched.staff && errors.staff}
                  </FormHelperText>
                </FormControl>
              </Box>

              <DialogActions>
                <Button
                  onClick={handleClose}
                  sx={{
                    backgroundColor: "#888",
                    color: "white",
                    marginRight: 1,
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#555",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#6870fa",
                    color: "white",
                    marginRight: 2,
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#3e4396",
                    },
                  }}
                >
                  Add Note
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const noteSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  client: yup.string(), // Optional
  staff: yup.string(), // Optional
});

const initialValues = {
  title: "",
  description: "",
  client: "",
  staff: "",
};

export default AddNoteDialog;
