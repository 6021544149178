const AustralianStates = Object.freeze({
    NSW: 'New South Wales',
    VIC: 'Victoria',
    QLD: 'Queensland',
    WA: 'Western Australia',
    SA: 'South Australia',
    TAS: 'Tasmania',
    ACT: 'Australian Capital Territory',
    NT: 'Northern Territory'
});

const AustralianStatesForHolidays = Object.freeze({
    NSW: 'New South Wales',
    VIC: 'Victoria',
    QLD: 'Queensland',
    WA: 'Western Australia',
    SA: 'South Australia',
    TAS: 'Tasmania',
    ACT: 'Australian Capital Territory',
    NT: 'Northern Territory',
    ALL: 'All'
});

const JobTypes = Object.freeze({
    ONCE: 'Once',
    DAILY: 'Daily',
    WEEKDAYS: 'Weekdays',
    WEEKEND: 'Weekend',
    WEEKLY: 'Weekly',
    FORTNIGHT: 'Fortnight',
});

const JobStates = Object.freeze({
    TODO: 'To Do',
    INPROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
});

const PaymentStatus = Object.freeze({
    PENDING: 'Pending',
    DONE: 'Done',
});

// Export for ES Modules (frontend or modern JavaScript bundlers)
export {AustralianStates, AustralianStatesForHolidays, JobTypes, JobStates, PaymentStatus};
