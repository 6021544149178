import {
  Box,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useState, useEffect } from "react";
import { tokens } from "../theme";
import { environment } from "../environment";
import axios from "axios";

const NoteCard = ({ title, description, staff, client, onEdit, onDelete }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false); // State to handle modal open/close
  const [staffName, setStaffName] = useState("");
  const [clientName, setClientName] = useState("");

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (staff) fetchStaffName();
    if (client) fetchClientName();
  }, [staff, client]);

  const fetchStaffName = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${environment.apiUrl}/staff/getStaffName/${staff}`,
        { headers }
      );
      const responseData = response.data;
      if (responseData.success) {
        setStaffName(responseData.fullName || "Unknown Staff");
      } else {
        setStaffName("Unavailable");
        console.error("Failed to fetch staff name:", responseData.message);
      }
    } catch (error) {
      setStaffName("Unavailable");
      console.error("Error fetching staff name:", error);
    }
  };

  const fetchClientName = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${environment.apiUrl}/client/getClientName/${client}`,
        { headers }
      );
      const responseData = response.data;
      if (responseData.success) {
        setClientName(responseData.fullName || "Unknown Client");
      } else {
        setClientName("Unavailable");
        console.error("Failed to fetch client name:", responseData.message);
      }
    } catch (error) {
      setClientName("Unavailable");
      console.error("Error fetching client name:", error);
    }
  };

  // Function to open the modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Function to close the modal
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: colors.primary[400],
          borderRadius: "8px",
          boxShadow: `0 2px 4px ${colors.grey[900]}`,
          padding: "16px",
          margin: "8px",
          width: { xs: "100%", sm: "45%", md: "30%" }, // Responsive width
          maxHeight: "240px", // Increased height to accommodate new fields
          display: "flex",
          flexDirection: "column", // Keep items in a single column
          alignItems: "flex-start", // Align items to the start
          cursor: "pointer", // Cursor pointer to indicate clickability
          transition: "transform 0.2s ease-in-out", // Optional hover effect
          "&:hover": {
            transform: "scale(1.02)",
          },
        }}
        onClick={handleOpen} // Open modal on click
      >
        {/* Title and Icons Container */}
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Typography
            variant="h5" // Use h5 variant
            sx={{
              color: colors.black[100],
              fontWeight: "bold", // Make the title bold
              whiteSpace: "nowrap", // Prevent text from wrapping
              overflow: "hidden", // Hide overflowing text
              textOverflow: "ellipsis", // Add ellipsis to truncated text
            }}
          >
            {title}
          </Typography>

          {/* Icons for Edit and Delete */}
          <Box>
            <Tooltip title="Edit">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation(); // Prevent card click event
                  onEdit(); // Correctly call the onEdit function when clicked
                }}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation(); // Prevent card click event
                  onDelete();
                }}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {/* Description */}
        <Typography
          variant="body1"
          sx={{
            color: colors.black[100],
            overflow: "hidden", // Hide overflowing text
            textOverflow: "ellipsis", // Add ellipsis to truncated text
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3, // Limit to 3 lines for description
            marginBottom: "8px", // Add some space at the bottom
            fontSize: "0.9rem",
          }}
        >
          {description}
        </Typography>

        {/* Staff */}
        {staff && (
          <Typography
            variant="body2"
            sx={{
              color: colors.black[100],
              fontSize: "0.9rem",
              marginBottom: "4px", // Add some space at the bottom
            }}
          >
            <strong>Staff:</strong> {staffName}
          </Typography>
        )}

        {/* Client */}
        {client && (
          <Typography
            variant="body2"
            sx={{
              color: colors.black[100],
              fontSize: "0.9rem",
            }}
          >
            <strong>Client:</strong> {clientName}
          </Typography>
        )}
      </Box>

      {/* Modal to display full details */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.black[100],
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: colors.primary[400],
            color: colors.black[100],
            mt: -1,
          }}
        >
          <Typography variant="body1" sx={{ fontSize: "1rem" }} gutterBottom>
            {description}
          </Typography>

          {staff && (
            <Typography variant="body2" sx={{ fontSize: "1rem" }} gutterBottom>
              <strong>Staff:</strong> {staffName}
            </Typography>
          )}

          {client && (
            <Typography variant="body2" sx={{ fontSize: "1rem" }}>
              <strong>Client:</strong> {clientName}
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NoteCard;
