import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "../../components/Header";
import axios from "axios";
import { environment } from "../../environment";
import ReusableTable from "../../components/ReusableTable";

const ViewJob = () => {
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState({
    mainJob: null,
    clientJobs: [],
    staffJobs: [],
  });

  useEffect(() => {
    fetchJobDetails();
  }, [id]);

  const token = localStorage.getItem("token");

  const fetchJobDetails = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      const response = await axios.get(
        `${environment.apiUrl}/super/getTaskById/${id}`,
        { headers }
      );
      const responseData = response.data;
      console.log("Job Details:", responseData);
  
      if (response.status === 200) {
        const clientJobs = await Promise.all(
          (responseData.clientJobs || []).map(async (job) => {
            const clientName = await fetchName("client", job.client);
            return {
              ...job,
              clientName,
            };
          })
        );
  
        const staffJobs = await Promise.all(
          (responseData.staffJobs || []).map(async (job) => {
            const clientName = await fetchName("client", job.client);
            const staffName = await fetchName("staff", job.assignedStaff);
            return {
              ...job,
              clientName,
              staffName,
            };
          })
        );
  
        setJobDetails({
          mainJob: responseData.mainJob || null,
          clientJobs,
          staffJobs,
        });
      } else {
        console.error("Failed to fetch job details.");
      }
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  };
  

  const fetchName = async (type, id) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      const response = await axios.get(
        `${environment.apiUrl}/${type}/get${type === "staff" ? "Staff" : "Client"}Name/${id}`,
        { headers }
      );
  
      const responseData = response.data;
  
      if (responseData.success) {
        return responseData.fullName || "";
      } else {
        console.error(`Failed to fetch ${type} name:`, responseData.message);
        return "";
      }
    } catch (error) {
      console.error(`Error fetching ${type} name:`, error);
      return "";
    }
  };


  // Columns for Client Jobs Table
  const clientJobsColumns = [
    { field: "jobName", headerName: "Job Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1.5 },
    { field: "clientName", headerName: "Client Name", flex: 1 },
    { field: "orgNoOfhours", headerName: "No. of Hours", flex: 1 },
    { field: "orgHourRate", headerName: "Hour Rate", flex: 1 },
    { field: "orgTotal", headerName: "Total", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    {
      field: "days",
      headerName: "Days",
      flex: 1.5,
      renderCell: (params) => params.value.join(", "),
    },
    { field: "jobStatus", headerName: "Status", flex: 0.8 },
  ];

  // Columns for Staff Jobs Table
  const staffJobsColumns = [
    { field: "jobName", headerName: "Job Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1.5 },
    { field: "clientName", headerName: "Client Name", flex: 1 },
    { field: "staffName", headerName: "Staff Name", flex: 1 },
    { field: "estNoOfhours", headerName: "Est. Hours", flex: 1 },
    { field: "staffHourRate", headerName: "Hourly Rate", flex: 1 },
    { field: "staffPayTotal", headerName: "Total Pay", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    {
      field: "days",
      headerName: "Days",
      flex: 1.5,
      renderCell: (params) => params.value.join(", "),
    },
    { field: "jobStatus", headerName: "Status", flex: 0.8 },
  ];

  return (
    <Box m="20px" height="85vh" overflow="auto" paddingRight="20px">
      <Header title={`View Job ID: ${id}`} subtitle="" />

      <Box ml={4}>
        <Grid container spacing={2}>
          {/* Main Job Details */}
          {jobDetails.mainJob &&
            Object.entries(jobDetails.mainJob)
              .filter(
                ([field]) =>
                  !["_id", "__v", "createdAt", "updatedAt"].includes(field)
              )
              .map(([field, value]) => (
                <React.Fragment key={field}>
                  <Grid item xs={1.8}>
                    <Typography variant="h5" component="span" fontWeight="bold">
                      {field}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="h5" component="span" fontWeight="bold">
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={9.2}>
                    <Typography>
                      {Array.isArray(value) ? value.join(", ") : value}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
        </Grid>
      </Box>

      {/* Client Jobs Table */}
      <Box mt={2} ml={4} mr={4}>
        <Typography variant="h5" component="span" fontWeight="bold">
          {"Client Jobs"}
        </Typography>
        <Box mt={3}>
        <ReusableTable
          title=""
          data={jobDetails.clientJobs}
          columns={clientJobsColumns}
          enableColumnsAndFilters={false}
        />
        </Box>
      </Box>

      {/* Staff Jobs Table */}
      <Box mt={4} ml={4} mr={4}>
      <Typography variant="h5" component="span" fontWeight="bold">
          {"Staff Jobs"}
        </Typography>
        <Box mt={3}>
        <ReusableTable
          title=""
          data={jobDetails.staffJobs}
          columns={staffJobsColumns}
          enableColumnsAndFilters={false}
        />
      </Box>
      </Box>
    </Box>
  );
};

export default ViewJob;
