import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  Snackbar,
  Alert as MuiAlert,
  AlertTitle,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormHelperText,
} from "@mui/material";
import Header from "./Header.jsx";
import { Formik } from "formik";
import { JobStates, PaymentStatus } from "../enum.js";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { environment } from "../environment.js";
import Swal from "sweetalert2";

const EditCreatedStaffJobDialog = ({ open, job, onSave, onClose }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [clients, setClients] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [manualOverride, setManualOverride] = useState(false);
  const [selectedStaffName, setSelectedStaffName] = useState(
    job?.staffName || ""
  );
  const [selectedClientName, setSelectedClientName] = useState(
    job?.clientName || ""
  );

  useEffect(() => {
    fetchClients();
    fetchStaffs();
  }, []);

  const token = localStorage.getItem("token");

  const fetchClients = async () => {
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(
        environment.apiUrl + "/client/getAllActiveClient",
        { headers }
      );
      if (response.data.success) {
        setClients(response.data.clients);
      } else {
        showError("Failed to fetch clients");
      }
    } catch (error) {
      showError("Error fetching clients");
    }
  };

  const fetchStaffs = async () => {
    try {
      const response = await axios.get(
        environment.apiUrl + "/staff/getAllActiveStaff",
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success) {
        setStaffs(response.data.staffs);
      } else {
        showError("Failed to fetch staffs");
      }
    } catch (error) {
      showError("Error fetching staffs");
    }
  };

  const showError = (message) => {
    setAlertSeverity("error");
    setAlertMessage(message);
    setOpenSnackbar(true);
  };

  const updateStaffJob = async (updatedJob) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      // console.log("updatedJob", updatedJob);
  
      const response = await axios.put(
        `${environment.apiUrl}/job/updateStaffJob/${updatedJob._id}`,
        updatedJob, // Send updated job fields in the body
        { headers }
      );
  
      if (response.data.success) {
        Swal.fire({
          title: "Success!",
          text: "Job updated successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
        onSave();
      } else {
        setAlertSeverity("error");
        setAlertMessage(`Failed to update job: ${response.data.message}`);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error updating staff job:", error);
      setAlertSeverity("error");
      setAlertMessage("Error updating staff job. Please try again.");
      setOpenSnackbar(true);
    }
  };

  const formatDateForInput = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{ zIndex: 1000 }}
    >
      <DialogTitle>
        <Header title="Edit Staff Job" subtitle="" />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            onClose={() => setOpenSnackbar(false)}
            severity={alertSeverity}
            elevation={6}
            variant="filled"
            sx={{ color: "#fff" }}
          >
            <AlertTitle>
              {alertSeverity === "success" ? "Success" : "Error"}
            </AlertTitle>
            {alertMessage}
          </MuiAlert>
        </Snackbar>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            jobName: job?.jobName || "",
            description: job?.description || "",
            assignedStaff: job?.assignedStaff?._id || "",
            client: job?.client?._id || "",
            estNoOfhours: job?.estNoOfhours || 0,
            staffHourRate: job?.staffHourRate || 0,
            staffPayTotal: job?.staffPayTotal || 0,
            staffExtraHours: job?.staffExtraHours || 0,
            staffExtraPayment: job?.staffExtraPayment || 0,
            jobPayment:
              job?.jobPayment ||
              job?.staffPayTotal + job?.staffExtraPayment ||
              0,
            jobStatus: job?.jobStatus || "",
            jobStartDate: job?.jobStartDate || "",
            paymentStatus: job?.paymentStatus || "",
          }}
          validationSchema={jobSchema}
          onSubmit={(values) => {
            const updatedJob = { ...job, ...values }; // Merge the original job with updated fields
            updateStaffJob(updatedJob);
            onSave();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns={
                  isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                }
              >
                <Typography fontWeight="bold" fontSize="16px">
                  Job Name*
                </Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  name="jobName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.jobName}
                  error={!!touched.jobName && !!errors.jobName}
                  helperText={touched.jobName && errors.jobName}
                  sx={{ mt: -3 }}
                />

                <Typography fontWeight="bold" fontSize="16px">
                  Description*
                </Typography>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  name="description"
                  multiline
                  rows={3}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                  sx={{ mt: -3 }}
                />

                <Box display="flex" gap="20px">
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Assigned Staff*
                    </Typography>
                    <FormControl
                      fullWidth
                      variant="filled"
                      error={!!touched.assignedStaff && !!errors.assignedStaff}
                    >
                      <Select
                        fullWidth
                        name="assignedStaff"
                        value={values.assignedStaff}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const selectedStaff = staffs.find(
                            (staff) => staff._id === e.target.value
                          );
                          setSelectedStaffName(
                            `${selectedStaff?.firstName || ""} ${
                              selectedStaff?.lastName || ""
                            }`
                          );
                        }}
                      >
                        {staffs.map((staff) => (
                          <MenuItem key={staff._id} value={staff._id}>
                            {staff.firstName} {staff.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.assignedStaff && errors.assignedStaff}
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Client*
                    </Typography>
                    <FormControl
                      fullWidth
                      variant="filled"
                      error={!!touched.client && !!errors.client}
                    >
                      <Select
                        fullWidth
                        name="client"
                        value={values.client}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const selectedClient = clients.find(
                            (client) => client._id === e.target.value
                          );
                          setSelectedClientName(
                            `${selectedClient?.firstName || ""} ${
                              selectedClient?.lastName || ""
                            }`
                          );
                        }}
                      >
                        {clients.map((client) => (
                          <MenuItem key={client._id} value={client._id}>
                            {client.firstName} {client.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.client && errors.client}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Box>

                <Box display="flex" gap="20px">
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Start Date/Time*
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="datetime-local"
                      name="jobStartDate"
                      value={formatDateForInput(values.jobStartDate)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={!!touched.jobStartDate && !!errors.jobStartDate}
                      helperText={touched.jobStartDate && errors.jobStartDate}
                    />
                  </Box>

                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Job Status*
                    </Typography>
                    <FormControl
                      fullWidth
                      variant="filled"
                      error={!!touched.jobStatus && !!errors.jobStatus}
                    >
                      <Select
                        fullWidth
                        variant="filled"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.jobStatus}
                        name="jobStatus"
                        error={!!touched.jobStatus && !!errors.jobStatus}
                      >
                        {Object.values(JobStates).map((status) => (
                          <MenuItem key={status} value={status}>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.jobStatus && errors.jobStatus}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Box>

                <Box display="flex" gap="20px">
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Estimated Hours*
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      name="estNoOfhours"
                      value={values.estNoOfhours}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        if (!manualOverride) {
                          setFieldValue(
                            "staffPayTotal",
                            (e.target.value || 0) * (values.staffHourRate || 0)
                          );
                        }
                      }}
                      error={!!touched.estNoOfhours && !!errors.estNoOfhours}
                      helperText={touched.estNoOfhours && errors.estNoOfhours}
                      inputProps={{ min: 0, step: "0.01" }}
                    />
                  </Box>

                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Hourly Rate*
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      name="staffHourRate"
                      value={values.staffHourRate}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        if (!manualOverride) {
                          setFieldValue(
                            "staffPayTotal",
                            (e.target.value || 0) * (values.estNoOfhours || 0)
                          );
                        }
                      }}
                      error={!!touched.staffHourRate && !!errors.staffHourRate}
                      helperText={touched.staffHourRate && errors.staffHourRate}
                      inputProps={{ min: 0, step: "0.01" }}
                    />
                  </Box>

                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Estimated Total*
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      name="staffPayTotal"
                      value={values.staffPayTotal}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setManualOverride(true);
                        setFieldValue("staffPayTotal", e.target.value);
                      }}
                      error={!!touched.staffPayTotal && !!errors.staffPayTotal}
                      helperText={touched.staffPayTotal && errors.staffPayTotal}
                      inputProps={{ min: 0, step: "0.01" }}
                    />
                  </Box>
                </Box>

                <Box display="flex" gap="20px">
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Extra Hours
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      name="staffExtraHours"
                      value={values.staffExtraHours}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={
                        !!touched.staffExtraHours && !!errors.staffExtraHours
                      }
                      helperText={
                        touched.staffExtraHours && errors.staffExtraHours
                      }
                      inputProps={{ min: 0, step: "0.01" }}
                    />
                  </Box>

                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Extra Payment
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      name="staffExtraPayment"
                      value={values.staffExtraPayment}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        const extraPayment = e.target.value || 0;
                        const updatedJobPayment =
                          values.staffPayTotal + extraPayment;
                        setFieldValue("jobPayment", updatedJobPayment); // Update jobPayment based on extra hours and extra payment
                      }}
                      error={
                        !!touched.staffExtraPayment &&
                        !!errors.staffExtraPayment
                      }
                      helperText={
                        touched.staffExtraPayment && errors.staffExtraPayment
                      }
                      inputProps={{ min: 0, step: "0.01" }}
                    />
                  </Box>

                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Job Payment
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      name="jobPayment"
                      value={values.jobPayment}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setManualOverride(true); // Indicate manual override
                        setFieldValue("jobPayment", e.target.value); // Use manually entered value
                      }}
                      error={!!touched.jobPayment && !!errors.jobPayment}
                      helperText={touched.jobPayment && errors.jobPayment}
                      inputProps={{ min: 0, step: "0.01" }}
                    />
                  </Box>
                </Box>

                <Typography fontWeight="bold" fontSize="16px">
                  Payment Status
                </Typography>
                <FormControl
                  fullWidth
                  variant="filled"
                  error={!!touched.paymentStatus && !!errors.paymentStatus}
                  sx={{ mt: -3 }}
                >
                  <Select
                    fullWidth
                    variant="filled"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.paymentStatus}
                    name="paymentStatus"
                    error={!!touched.paymentStatus && !!errors.paymentStatus}
                  >
                    {Object.values(PaymentStatus).map((status) => (
                      <MenuItem key={status} value={status}>
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched.paymentStatus && errors.paymentStatus}
                  </FormHelperText>
                </FormControl>
              </Box>

              <DialogActions>
                <Button
                  onClick={onClose}
                  sx={{
                    backgroundColor: "#888",
                    color: "white",
                    marginTop: 2,
                    marginRight: 1,
                    marginBottom: 2,
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#555",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#6870fa",
                    color: "white",
                    marginTop: 2,
                    marginRight: 2,
                    marginBottom: 2,
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#3e4396",
                    },
                  }}
                >
                  Update Staff Job
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const jobSchema = yup.object().shape({
  jobName: yup.string().required("Job Name is required"),
  description: yup.string().required("Description is required"),
  assignedStaff: yup.string().required("Staff is required"),
  client: yup.string().required("Client is required"),
  estNoOfhours: yup.number().required("Estimated Hours are required"),
  staffHourRate: yup.number().required("Hourly Rate is required"),
  staffPayTotal: yup.number().required("Total is required"),
  jobStatus: yup.string().required("Status is required"),
  jobStartDate: yup
    .string()
    .required("Start Date and Time is required")
});

export default EditCreatedStaffJobDialog;
