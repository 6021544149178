export const formatDate = (dateTime) => {
  const date = new Date(dateTime);
  return date.toLocaleDateString("en-CA"); // This will return the format yyyy-MM-dd
};

export const formatDateTime = (dateTime) => {
  const date = new Date(dateTime);
  return date
    .toLocaleString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // Use 12-hour format
    })
    .replace(",", "") // This will return the format yyyy-MM-dd HH:mm:ss AM/PM
    .replace(/\s?(a\.m\.|p\.m\.)/i, (match) => match.toUpperCase().replace(/\./g, "")); // Replace 'a.m.'/'p.m.' with 'AM'/'PM'
};
