import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../theme.js";

const ReusableTable = ({ 
  title, 
  data, 
  columns = [], 
  enableColumnsAndFilters = true 
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box mt={-2}>
      {title && (
        <Typography fontWeight="bold" fontSize="16px" gutterBottom>
          {title}
        </Typography>
      )}
      <Box
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
            height: "40px", // Reduced height
            minHeight: "40px", // Ensure consistent reduction
            padding: "0 8px", // Adjust padding to reduce space further
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            lineHeight: "normal", // Adjust line height to match height reduction
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data.map((row, index) => ({
            id: row.id || index + 1, // Add unique ID if not present
            ...row,
          }))}
          columns={columns}
          components={enableColumnsAndFilters ? { Toolbar: GridToolbar } : {}}
          disableColumnFilter={!enableColumnsAndFilters}
          disableColumnMenu={!enableColumnsAndFilters}
          disableSelectionOnClick
          hideFooter={!enableColumnsAndFilters} // Hide pagination if disabled
          autoHeight
        />
      </Box>
    </Box>
  );
};

export default ReusableTable;
