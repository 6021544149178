import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  useTheme,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import * as yup from "yup";
import Header from "../../components/Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import EditIcon from "@mui/icons-material/Edit";
import Calendar from "react-calendar"; // Use react-calendar for date selection
import "react-calendar/dist/Calendar.css"; // Import calendar styles
import { environment } from "../../environment"; // Import environment for API URL
import "./Holidays.css"; // Custom styles for holidays
import DeleteIcon from "@mui/icons-material/Delete";
import AddHolidayDialog from "../../components/AddHolidayDialog";

const Holidays = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1); // Month is 0-indexed
  const [holidays, setHolidays] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [calendarValue, setCalendarValue] = useState(new Date()); // Synchronize calendar

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [newHoliday, setNewHoliday] = useState({
    holidayName: "",
    states: [],
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem("token");

  // Fetch holidays based on the selected year and month
  const fetchHolidays = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${environment.apiUrl}/holidays/holidays/${year}/${month}`,
        { headers }
      );

      if (response.data) {
        const localHolidays = response.data.map((holiday) => ({
          ...holiday,
          date: new Date(holiday.date).toISOString().split("T")[0], // Convert to local date (YYYY-MM-DD)
        }));
        setHolidays(localHolidays);

        // Synchronize calendarValue
        setCalendarValue(new Date(year, month - 1));
      } else {
        Swal.fire("Error", "Failed to fetch holidays", "error");
      }
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  useEffect(() => {
    fetchHolidays();
  }, [year, month]); // Fetch holidays when year or month changes

  // Handle changes in the year input box
  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setYear(newYear);

    // Update the calendar value when the year changes
    const updatedCalendarValue = new Date(newYear, month - 1);
    setCalendarValue(updatedCalendarValue);
  };

  // Handle changes in the month input box
  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    setMonth(newMonth);

    // Update the calendar value when the month changes
    const updatedCalendarValue = new Date(year, newMonth - 1);
    setCalendarValue(updatedCalendarValue);
  };

  const handleDateClick = (date) => {
    // Format the selected date in YYYY-MM-DD for local timezone
    const formattedDate = date.toLocaleDateString("en-CA"); // "en-CA" ensures YYYY-MM-DD format
    const isAlreadyHoliday = holidays.some(
      (holiday) => holiday.date === formattedDate
    );
    if (isAlreadyHoliday) {
      return; // Do not open the dialog box
    }

    setSelectedDate(formattedDate); // Pass the formatted date to the dialog
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewHoliday({ holidayName: "", states: [] });
  };

  const handleAddHoliday = () => {
    if (selectedDate === "" || newHoliday.states.length === 0) {
      Swal.fire("Error", "No date or state selected.", "error");
      return;
    }

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .post(
          `${environment.apiUrl}/holidays/addHolidays`,
          { date: selectedDate, ...newHoliday },
          { headers }
        )
        .then((response) => {
          if (response.status === 200) {
            fetchHolidays(); // Refresh holidays list
            Swal.fire("Success", "Holiday added successfully", "success");

            // Explicitly synchronize calendar
            setCalendarValue(new Date(year, month - 1));
          } else {
            Swal.fire("Error", response.data.message, "error");
          }
        })
        .catch((error) => {
          Swal.fire("Error", "Failed to add holiday", "error");
        });
    } catch (error) {
      Swal.fire("Error", "Failed to add holidays", "error");
    }

    handleCloseDialog();
  };

  const handleCalendarNavigate = (date) => {
    setYear(date.getFullYear());
    setMonth(date.getMonth() + 1);
    setCalendarValue(date);
  };

  const handleDeleteHoliday = async (holidayId) => {
    Swal.fire({
      title: "Are you sure delete this holiday?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        axios
          .delete(`${environment.apiUrl}/holidays/deleteHoliday/${holidayId}`, {
            headers,
          })
          .then((response) => {
            if (response.status === 200) {
              fetchHolidays(); // Refresh holidays list
              Swal.fire("Success", "Holiday deleted successfully", "success");
            } else {
              Swal.fire("Error", "Failed to delete the holiday.", "error");
            }
          })
          .catch((error) => {
            console.error("Error deleting holiday:", error);
            Swal.fire("Error", "Failed to delete holiday", "error");
          });
      }
    });

    fetchHolidays();
  };

  // Function to determine if a date is a holiday
  const isHoliday = (date) => {
    const localDateString = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    return holidays.some((holiday) => holiday.date === localDateString);
  };

  // Function to determine if a date is selected
  const isSelected = (date) => {
    const localDateString = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    return selectedDates.includes(localDateString);
  };

  return (
    <Box m="20px">
      <Header title="Manage Holidays" />

      {/* Calendar and Holiday List */}
      <Box display="flex" justifyContent="space-between" marginTop="-215x">
        {/* Left Half - Calendar */}
        <Box flex={1} p={2}>
          {/* Year and Month Selection */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="10px"
          >
            <Box flex={1}>
              {/* Year Select */}
              <FormControl
                variant="outlined"
                sx={{
                  minWidth: 120,
                  marginRight: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2, // Ensure rounded borders
                  },
                }}
              >
                <Typography
                  variant="h5"
                  component="span"
                  fontWeight="bold"
                  mb={1}
                >
                  Year
                </Typography>
                <Select
                  value={year}
                  onChange={handleYearChange}
                  sx={{
                    borderRadius: 2, // Keep the border clean
                  }}
                >
                  {Array.from(
                    { length: 8 },
                    (_, i) => new Date().getFullYear() - 2 + i
                  ).map((yr) => (
                    <MenuItem key={yr} value={yr}>
                      {yr}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Month Select */}
              <FormControl
                variant="outlined"
                sx={{
                  minWidth: 120,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2, // Ensure rounded borders
                  },
                }}
              >
                <Typography
                  variant="h5"
                  component="span"
                  fontWeight="bold"
                  mb={1}
                >
                  Month
                </Typography>
                <Select
                  value={month}
                  onChange={handleMonthChange}
                  sx={{
                    borderRadius: 2, // Keep the border clean
                  }}
                >
                  {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
                    <MenuItem key={m} value={m}>
                      {new Date(0, m - 1).toLocaleString("default", {
                        month: "long",
                      })}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          <h4>Select a Date</h4>
          <Calendar
            key={`${year}-${month}`}
            value={calendarValue} // Reflect the updated calendarValue
            onClickDay={handleDateClick}
            onActiveStartDateChange={({ activeStartDate }) =>
              handleCalendarNavigate(activeStartDate)
            } // Synchronize input and calendar
            tileClassName={({ date, view }) => {
              const dateString = date.toISOString().split("T")[0];

              // Highlight if the date is selected or is a holiday
              if (isSelected(date)) {
                return "selected-date"; // Custom class for selected dates
              }

              if (isHoliday(date)) {
                return "holiday-date"; // Custom class for holidays
              }

              // Default return for all other dates, including 1st of the month and today's date
              return "no-highlight"; // This class ensures no special styling
            }}
          />

          <AddHolidayDialog
            open={openDialog}
            handleClose={handleCloseDialog}
            handleAddHoliday={handleAddHoliday}
            selectedDate={selectedDate}
            newHoliday={newHoliday}
            setNewHoliday={setNewHoliday}
          />
        </Box>

        {/* Right Half - Holidays List */}
        <Box flex={1} p={2} height="70vh">
          <h4>
            Holidays in{" "}
            {new Date(0, month - 1).toLocaleString("default", {
              month: "long",
            })}
            , {year}
          </h4>
          <DataGrid
            rows={holidays.map((holiday) => ({
              id: holiday._id,
              date: holiday.date,
              holidayName: holiday.holidayName || "No Name",
              states: holiday.states,
            }))}
            columns={[
              { field: "date", headerName: "Date", flex: 1 },
              { field: "holidayName", headerName: "Holiday Name", flex: 1 },
              { field: "states", headerName: "States", flex: 1 },
              {
                field: "actions",
                headerName: "Actions",
                flex: 0.5,
                renderCell: (params) => (
                  <Box>
                    {/* <Tooltip title="Edit">
                      <IconButton
                        onClick={() => handleDeleteHoliday(params.row.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDeleteHoliday(params.row.id)}
                        sx={{ color: colors.redAccent[500] }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ),
              },
            ]}
            disableSelectionOnClick
            sx={{
              "& .MuiDataGrid-cell": {
                border: "none",
                fontSize: "14px",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.greenAccent[700],
                fontSize: "14px",
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: colors.greenAccent[700],
                fontSize: "14px",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Holidays;
