import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  Snackbar,
  Alert as MuiAlert,
  AlertTitle,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormHelperText,
} from "@mui/material";
import Header from "./Header.jsx";
import { Formik } from "formik";
import { JobStates, PaymentStatus } from "../enum.js";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { environment } from "../environment.js";
import Swal from "sweetalert2";

const EditCreatedClientJobDialog = ({ open, job, onSave, onClose }) => {
  const [clients, setClients] = useState([]);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [manualOverride, setManualOverride] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [selectedClientName, setSelectedClientName] = useState(""); // Store client name

  useEffect(() => {
    fetchClients();
  }, []);

  const formatDateForInput = (date) => {
    if (!date) return ""; // Handle null/undefined
    const parsedDate = new Date(date); // Parse the date
    return parsedDate.toISOString().split("T")[0]; // Extract yyyy-MM-dd
  };

  const fetchClients = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(
        environment.apiUrl + "/client/getAllActiveClient",
        { headers }
      );
      if (response.data.success) {
        setClients(response.data.clients);
      } else {
        setAlertSeverity("error");
        setAlertMessage("Failed to fetch clients");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to fetch clients");
      setOpenSnackbar(true);
    }
  };

  const updateClientJob = async (updatedJob) => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      // console.log("updatedJob", updatedJob);
  
      const response = await axios.put(
        `${environment.apiUrl}/job/updateClientJob/${updatedJob._id}`,
        updatedJob, // Send updated job fields in the body
        { headers }
      );
  
      if (response.data.success) {
        Swal.fire({
          title: "Success!",
          text: "Job updated successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
        onSave();
      } else {
        setAlertSeverity("error");
        setAlertMessage(`Failed to update job: ${response.data.message}`);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error updating client job:", error);
      setAlertSeverity("error");
      setAlertMessage("Error updating client job. Please try again.");
      setOpenSnackbar(true);
    }
  };
  

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        zIndex: 1000,
      }}
    >
      <DialogTitle>
        <Header title="Edit Client Job" subtitle="" />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            onClose={() => setOpenSnackbar(false)}
            severity={alertSeverity}
            elevation={6}
            variant="filled"
            sx={{ color: "#fff" }}
          >
            <AlertTitle>
              {alertSeverity === "success" ? "Success" : "Error"}
            </AlertTitle>
            {alertMessage}
          </MuiAlert>
        </Snackbar>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            jobName: job?.jobName || "",
            description: job?.description || "",
            client: job?.client?._id || "", // Ensures client ID is initialized correctly
            orgNoOfhours: job?.orgNoOfhours || 0,
            orgHourRate: job?.orgHourRate || 0,
            orgTotal: job?.orgTotal || 0,
            jobDate: formatDateForInput(job?.jobDate), // Formats date correctly
            jobStatus: job?.jobStatus || "",
            paymentStatus: job?.paymentStatus || "",
          }}
          validationSchema={jobSchema}
          onSubmit={(values) => {
            const updatedJob = { ...job, ...values }; // Merge the original job with updated fields
            updateClientJob(updatedJob); // Pass updated job back to parent component
            onSave(); // Close the dialog
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} height="41vh">
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns={
                  isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                }
              >
                <Typography fontWeight="bold" fontSize="16px">
                  Job Name*
                </Typography>
                <Box mt={-3}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.jobName}
                    name="jobName"
                    error={!!touched.jobName && !!errors.jobName}
                    helperText={touched.jobName && errors.jobName}
                  />
                </Box>
                <Typography fontWeight="bold" fontSize="16px">
                  Description*
                </Typography>
                <Box mt={-3}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    name="description"
                    multiline
                    rows={3}
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                  />
                </Box>
                <Box display="flex" alignItems="center" gap={4} mt={-1}>
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Client*
                    </Typography>
                    <FormControl
                      fullWidth
                      variant="filled"
                      error={!!touched.client && !!errors.client}
                    >
                      <Select
                        fullWidth
                        variant="filled"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const selectedClient = clients.find(
                            (client) => client._id === e.target.value
                          );
                          const fullName = `${
                            selectedClient?.firstName || ""
                          } ${selectedClient?.lastName || ""}`.trim();
                          setFieldValue("clientName", fullName); // Update Formik state
                        }}
                        value={values.client} // Ensure correct initialization
                        name="client"
                        error={!!touched.client && !!errors.client}
                        helperText={touched.client && errors.client}
                      >
                        {clients.map((client) => (
                          <MenuItem key={client._id} value={client._id}>
                            {client.firstName} {client.lastName}
                          </MenuItem>
                        ))}
                      </Select>

                      <FormHelperText>
                        {touched.client && errors.client}
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Job Status*
                    </Typography>
                    <FormControl
                      fullWidth
                      variant="filled"
                      error={!!touched.jobStatus && !!errors.jobStatus}
                    >
                      <Select
                        fullWidth
                        variant="filled"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.jobStatus}
                        name="jobStatus"
                        error={!!touched.jobStatus && !!errors.jobStatus}
                      >
                        {Object.values(JobStates).map((status) => (
                          <MenuItem key={status} value={status}>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.jobStatus && errors.jobStatus}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
                <Box display="flex" gap="20px" mt={-1}>
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Original Number of Hours*
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        if (!manualOverride) {
                          setFieldValue(
                            "orgTotal",
                            (e.target.value || 0) * (values.orgHourRate || 0)
                          );
                        }
                      }}
                      value={values.orgNoOfhours || ""}
                      name="orgNoOfhours"
                      inputProps={{ min: 0, step: "0.01" }}
                      onWheel={(e) => e.target.blur()}
                      error={!!touched.orgNoOfhours && !!errors.orgNoOfhours}
                      helperText={touched.orgNoOfhours && errors.orgNoOfhours}
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Original Hourly Rate*
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        if (!manualOverride) {
                          setFieldValue(
                            "orgTotal",
                            (values.orgNoOfhours || 0) * (e.target.value || 0)
                          );
                        }
                      }}
                      value={values.orgHourRate || ""}
                      name="orgHourRate"
                      inputProps={{ min: 0, step: "0.01" }}
                      onWheel={(e) => e.target.blur()}
                      error={!!touched.orgHourRate && !!errors.orgHourRate}
                      helperText={touched.orgHourRate && errors.orgHourRate}
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Original Total*
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setManualOverride(true); // Enable manual override
                        setFieldValue("orgTotal", e.target.value); // Explicitly set the field value
                      }}
                      value={values.orgTotal || ""}
                      name="orgTotal"
                      inputProps={{ min: 0, step: "0.01" }}
                      onWheel={(e) => e.target.blur()}
                      error={!!touched.orgTotal && !!errors.orgTotal}
                      helperText={touched.orgTotal && errors.orgTotal}
                    />
                  </Box>
                </Box>

                <Box display="flex" gap="20px" mt={-1}>
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Job Date*
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="date" // Only date is required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={formatDateForInput(values.jobDate)} // Ensure only date in "yyyy-MM-dd"
                      name="jobDate"
                      error={!!touched.jobDate && !!errors.jobDate}
                      helperText={touched.jobDate && errors.jobDate}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Payment Status*
                    </Typography>
                    <FormControl
                      fullWidth
                      variant="filled"
                      error={!!touched.paymentStatus && !!errors.paymentStatus}
                    >
                      <Select
                        fullWidth
                        variant="filled"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.paymentStatus}
                        name="paymentStatus"
                        error={
                          !!touched.paymentStatus && !!errors.paymentStatus
                        }
                      >
                        {Object.values(PaymentStatus).map((status) => (
                          <MenuItem key={status} value={status}>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.paymentStatus && errors.paymentStatus}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <DialogActions>
                <Button
                  onClick={onClose}
                  sx={{
                    backgroundColor: "#888",
                    color: "white",
                    marginTop: 2,
                    marginRight: 1,
                    marginBottom: 2,
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#555",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#6870fa",
                    color: "white",
                    marginTop: 2,
                    marginRight: 2,
                    marginBottom: 2,
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#3e4396",
                    },
                  }}
                >
                  Update Client Job
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const jobSchema = yup.object().shape({
  jobName: yup.string().required("Job Name is required"),
  description: yup.string().required("Description is required"),
  client: yup.string().required("Client is required"),
  orgNoOfhours: yup.number().required("Number of Hours is required"),
  orgHourRate: yup.number().required("Hourly Rate is required"),
  orgTotal: yup.number().required("Total is required"),
  jobStatus: yup.string().required("Staus is required"),
  jobDate: yup.string().required("Start Date and Time is required"),
  paymentStatus: yup.string().required("Payment Status is required"),
});

export default EditCreatedClientJobDialog;
