import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  Snackbar,
  Alert as MuiAlert,
  AlertTitle,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormHelperText,
} from "@mui/material";
import Header from "./Header";
import { Formik } from "formik";
import { JobTypes, JobStates } from "../enum.js";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { environment } from "../environment";

const AddStaffJobDialog = ({
  open,
  handleFormSubmit,
  handleAddJob,
  handleClose,
}) => {
  const jobStates = Object.values(JobTypes);
  const [loading, setLoading] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [clients, setClients] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [manualOverride, setManualOverride] = useState(false); // Track manual override of `Original Total`
  const [selectedStaffName, setSelectedStaffName] = useState(""); // Store staff name
  const [selectedClientName, setSelectedClientName] = useState(""); // Store client name

  useEffect(() => {
    fetchClients();
    fetchStaffs();
  }, []);
  const token = localStorage.getItem("token");

  const fetchClients = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        environment.apiUrl + "/client/getAllActiveClient",
        { headers }
      );

      if (response.data.success) {
        setClients(response.data.clients);
      } else {
        setAlertSeverity("error");
        setAlertMessage("Failed to fetch clients");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to fetch clients");
      setOpenSnackbar(true);
    }
  };

  const fetchStaffs = async () => {
    try {
      //const token = localStorage.getItem("token"); // Assuming the token is stored in localStorage
      const response = await axios.get(
        environment.apiUrl + "/staff/getAllActiveStaff",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        setStaffs(response.data.staffs);
      } else {
        setAlertSeverity("error");
        setAlertMessage("Failed to fetch staffs");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error fetching staffs:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to fetch staffs");
      setOpenSnackbar(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      sx={{
        zIndex: 1000,
      }}
    >
      <DialogTitle>
        <Header title="Add a Staff Job" subtitle="" />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MuiAlert
            onClose={() => setOpenSnackbar(false)}
            severity={alertSeverity}
            elevation={6}
            variant="filled"
            sx={{ color: "#fff" }}
          >
            <AlertTitle>
              {alertSeverity === "success" ? "Success" : "Error"}
            </AlertTitle>
            {alertMessage}
          </MuiAlert>
        </Snackbar>
      </DialogTitle>

      <DialogContent>
        <Formik
          onSubmit={(values, { resetForm }) => {
            const newJob = {
              jobName: values.jobName,
              description: values.description,
              assignedStaff: values.assignedStaff,
              client: values.client,
              staffName: selectedStaffName,
              clientName: selectedClientName,
              jobStartDate: values.jobStartDate,
              estNoOfhours: values.estNoOfhours,
              staffHourRate: values.staffHourRate,
              staffPayTotal: values.staffPayTotal,
              jobStatus: values.jobStatus,
            };
            handleAddJob(newJob); // Pass the job data back to AddJob
            resetForm(); // Reset the form fields
            setManualOverride(false);
          }}
          initialValues={initialValues}
          validationSchema={jobSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} height="41vh">
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns={
                  isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                }
              >
                <Typography fontWeight="bold" fontSize="16px">
                  Job Name*
                </Typography>
                <Box mt={-3}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.jobName}
                    name="jobName"
                    error={!!touched.jobName && !!errors.jobName}
                    helperText={touched.jobName && errors.jobName}
                  />
                </Box>
                <Typography fontWeight="bold" fontSize="16px">
                  Description*
                </Typography>
                <Box mt={-3}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    name="description"
                    multiline
                    rows={3}
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                  />
                </Box>
                <Typography fontWeight="bold" fontSize="16px">
                  Assigned Staff*
                </Typography>
                <Box mt={-3}>
                  <FormControl
                    fullWidth
                    variant="filled"
                    error={!!touched.assignedStaff && !!errors.assignedStaff}
                  >
                    <Select
                      fullWidth
                      variant="filled"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        const selectedStaff = staffs.find(
                          (staff) => staff._id === e.target.value
                        );
                        setSelectedStaffName(
                          `${selectedStaff.firstName} ${selectedStaff.lastName}`
                        );
                      }}
                      value={values.assignedStaff}
                      name="assignedStaff"
                    >
                      {staffs.map((staff) => (
                        <MenuItem key={staff._id} value={staff._id}>
                          {staff.firstName} {staff.lastName}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.assignedStaff && errors.assignedStaff}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box display="flex" alignItems="center" gap={4} mt={-1}>
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Client*
                    </Typography>
                    <FormControl
                      fullWidth
                      variant="filled"
                      error={!!touched.client && !!errors.client}
                    >
                      <Select
                        fullWidth
                        variant="filled"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          const selectedClient = clients.find(
                            (client) => client._id === e.target.value
                          );
                          setSelectedClientName(
                            `${selectedClient.firstName} ${selectedClient.lastName}`
                          );
                        }}
                        value={values.client}
                        name="client"
                      >
                        {clients.map((client) => (
                          <MenuItem key={client._id} value={client._id}>
                            {client.firstName} {client.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.client && errors.client}
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Job Status*
                    </Typography>
                    <FormControl
                      fullWidth
                      variant="filled"
                      error={!!touched.jobStatus && !!errors.jobStatus}
                    >
                      <Select
                        fullWidth
                        variant="filled"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.jobStatus}
                        name="jobStatus"
                        error={!!touched.jobStatus && !!errors.jobStatus}
                      >
                        {Object.values(JobStates).map((status) => (
                          <MenuItem key={status} value={status}>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched.jobStatus && errors.jobStatus}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Box>
                <Box display="flex" gap="20px" mt={-1}>
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Estimated Number of Hours*
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        if (!manualOverride) {
                          setFieldValue(
                            "staffPayTotal",
                            (e.target.value || 0) * (values.staffHourRate || 0)
                          );
                        }
                      }}
                      value={values.estNoOfhours || ""}
                      name="estNoOfhours"
                      inputProps={{ min: 0, step: "0.01" }}
                      onWheel={(e) => e.target.blur()}
                      error={!!touched.estNoOfhours && !!errors.estNoOfhours}
                      helperText={touched.estNoOfhours && errors.estNoOfhours}
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Hourly Rate*
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        if (!manualOverride) {
                          setFieldValue(
                            "staffPayTotal",
                            (e.target.value || 0) * (values.estNoOfhours || 0)
                          );
                        }
                      }}
                      value={values.staffHourRate || ""}
                      name="staffHourRate"
                      inputProps={{ min: 0, step: "0.01" }}
                      onWheel={(e) => e.target.blur()}
                      error={!!touched.staffHourRate && !!errors.staffHourRate}
                      helperText={touched.staffHourRate && errors.staffHourRate}
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography fontWeight="bold" fontSize="16px">
                      Estimated Total*
                    </Typography>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="number"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setManualOverride(true); // Enable manual override
                        setFieldValue("staffPayTotal", e.target.value);
                      }}
                      value={values.staffPayTotal || ""}
                      name="staffPayTotal"
                      inputProps={{ min: 0, step: "0.01" }}
                      onWheel={(e) => e.target.blur()}
                      error={!!touched.staffPayTotal && !!errors.staffPayTotal}
                      helperText={touched.staffPayTotal && errors.staffPayTotal}
                    />
                  </Box>
                </Box>

                <Typography fontWeight="bold" fontSize="16px" mt={-1}>
                  Start Date/Time*
                </Typography>
                <Box mt={-3}>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="datetime-local"
                    name="jobStartDate"
                    value={values.jobStartDate || ""} // Ensure value is a string
                    onBlur={handleBlur} // Mark field as touched
                    onChange={(e) => {
                      setFieldValue("jobStartDate", e.target.value); // Update Formik's value
                    }}
                    error={!!touched.jobStartDate && !!errors.jobStartDate} // Show error if touched and invalid
                    helperText={touched.jobStartDate && errors.jobStartDate} // Display error message
                    inputProps={{
                      min: new Date().toISOString().slice(0, 16), // Restrict past dates
                    }}
                  />
                </Box>
              </Box>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  sx={{
                    backgroundColor: "#888",
                    color: "white",
                    marginRight: 1,
                    marginTop: 2,
                    marginBottom: 2,
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#555",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#6870fa",
                    color: "white",
                    marginTop: 2,
                    marginRight: 2,
                    marginBottom: 2,
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#3e4396",
                    },
                  }}
                >
                  Add Job
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const jobSchema = yup.object().shape({
  jobName: yup.string().required("Job Name is required"),
  description: yup.string().required("Description is required"),
  client: yup.string().required("Client is required"),
  assignedStaff: yup.string().required("Staff is required"),
  estNoOfhours: yup.number().required("Number of Hours is required"),
  staffHourRate: yup.number().required("Hourly Rate is required"),
  staffPayTotal: yup.number().required("Total is required"),
  jobStatus: yup.string().required("Type is required"),
  jobStartDate: yup
    .string()
    .required("Start Date and Time is required") // Empty strings are invalid
    .test("isValidDate", "Invalid date format", (value) => {
      if (!value) return false; // Empty value is invalid
      const date = new Date(value);
      return !isNaN(date.getTime()); // Must be a valid date
    })
    .test(
      "isFutureDate",
      "Date and time must be today or in the future",
      (value) => {
        if (!value) return false; // Skip further checks if invalid
        const date = new Date(value);
        return date >= new Date(); // Ensure it's a future date
      }
    ),
});

const initialValues = {
  jobName: "",
  description: "",
  client: "",
  assignedStaff: "",
  estNoOfhours: "",
  staffHourRate: "",
  staffPayTotal: "",
  jobStatus: JobStates.TODO,
  jobStartDate: "",
};

export default AddStaffJobDialog;
