import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header";
import { environment } from '../../environment';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

const jobSchema = yup.object().shape({
  jobName: yup.string().required("Job Name is required"),
  description: yup.string().required("Description is required"),
  client: yup.string().required("Client is required"),
  assignedStaff: yup.string().required("Assigned Staff is required"),
  orgNoOfhours: yup.number().nullable().required("Number of Hours is required"),
  orgHourRate: yup.number().nullable().required("Hourly Rate is required"),
  estNoOfhours: yup.number().nullable().required("Estimated Hours is required"),
  staffHourRate: yup.number().nullable().required("Hourly Rate is required"),
  staffExtraHours: yup.number().nullable(),
  staffExtraPayment: yup.number().nullable(),
  notes: yup.string(),
});

const EditJob = () => {
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const [jobDetails, setJobDetails] = useState({});
  const [clients, setClients] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const navigate = useNavigate();

  useEffect(() => {
    fetchClients();
    fetchStaffs();
    fetchJobDetails();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${environment.apiUrl}/client/getAllActiveClient`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.success) {
        setClients(response.data.clients);
      } else {
        throw new Error("Failed to fetch clients.");
      }
    } catch (error) {
      handleSnackbar("error", "Error fetching clients.");
      console.error("Error fetching clients:", error);
    }
  };

  const fetchStaffs = async () => {
    try {
      const response = await axios.get(`${environment.apiUrl}/staff/getAllActiveStaff`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.success) {
        setStaffs(response.data.staffs);
      } else {
        throw new Error("Failed to fetch staffs.");
      }
    } catch (error) {
      handleSnackbar("error", "Error fetching staffs.");
      console.error("Error fetching staffs:", error);
    }
  };

  const fetchJobDetails = async () => {
    try {
      const response = await axios.get(`${environment.apiUrl}/job/getJobById/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.success) {
        const job = response.data.job;
        setJobDetails({
          jobName: job.jobName || "",
          description: job.description || "",
          client: job.client?._id || "",
          assignedStaff: job.assignedStaff?._id || "",
          orgNoOfhours: job.orgNoOfhours || 0,
          orgHourRate: job.orgHourRate || 0,
          estNoOfhours: job.estNoOfhours || 0,
          staffHourRate: job.staffHourRate || 0,
          staffExtraHours: job.staffExtraHours || 0,
          staffExtraPayment: job.staffExtraPayment || 0,
          notes: job.notes || "",
        });
      } else {
        throw new Error(response.data.message || "Failed to fetch job details.");
      }
    } catch (error) {
      handleSnackbar("error", "Error fetching job details.");
      console.error("Error fetching job details:", error);
    }
  };

  const handleSnackbar = (severity, message) => {
    setSnackbar({ open: true, severity, message });
  };

  const handleUpdateJob = async (values) => {
    setIsLoading(true);
    try {
      const response = await axios.put(`${environment.apiUrl}/job/updatedJob/${id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        handleSnackbar("success", "Job updated successfully.");
        setTimeout(() => navigate("/jobs"), 2000);
      } else {
        throw new Error(response.data.message || "Failed to update job.");
      }
    } catch (error) {
      handleSnackbar("error", `Error updating job: ${error.message}`);
      console.error("Error updating job:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Dynamic fields configuration
  const dynamicFields = [
    { name: "jobName", label: "Job Name", type: "text" },
    { name: "description", label: "Description", type: "text", multiline: true, rows: 3 },
    { name: "orgNoOfhours", label: "Original Hours", type: "number" },
    { name: "orgHourRate", label: "Hourly Rate", type: "number" },
    { name: "estNoOfhours", label: "Estimated Hours", type: "number" },
    { name: "staffHourRate", label: "Staff Hourly Rate", type: "number" },
    { name: "staffExtraHours", label: "Extra Hours", type: "number" },
    { name: "staffExtraPayment", label: "Extra Payment", type: "number" },
  ];

  return (
    <Box m="20px" height="85vh" overflow="auto" paddingRight="20px">
      <Header title={`Edit Job ID: ${id}`} subtitle="" />
      <Formik
        initialValues={jobDetails}
        enableReinitialize
        validationSchema={jobSchema}
        onSubmit={handleUpdateJob}
      >
        {({ values, handleChange, handleBlur, touched, errors }) => (
          <Form>
            <Grid container spacing={2}>
              {dynamicFields.map((field, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Field
                    as={TextField}
                    name={field.name}
                    label={field.label}
                    type={field.type}
                    fullWidth
                    value={values[field.name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched[field.name] && !!errors[field.name]}
                    helperText={touched[field.name] && errors[field.name]}
                  />
                </Grid>
              ))}

              {/* Client Select */}
              <Grid item xs={12} sm={6}>
                <Field
                  as={Select}
                  name="client"
                  label="Client"
                  fullWidth
                  value={values.client}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.client && !!errors.client}
                >
                  {clients.map((client) => (
                    <MenuItem key={client._id} value={client._id}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Field>
                {touched.client && errors.client && (
                  <Typography color="error" variant="body2">
                    {errors.client}
                  </Typography>
                )}
              </Grid>

              {/* Assigned Staff Select */}
              <Grid item xs={12} sm={6}>
                <Field
                  as={Select}
                  name="assignedStaff"
                  label="Assigned Staff"
                  fullWidth
                  value={values.assignedStaff}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.assignedStaff && !!errors.assignedStaff}
                >
                  {staffs.map((staff) => (
                    <MenuItem key={staff._id} value={staff._id}>
                      {staff.firstName} {staff.lastName}
                    </MenuItem>
                  ))}
                </Field>
                {touched.assignedStaff && errors.assignedStaff && (
                  <Typography color="error" variant="body2">
                    {errors.assignedStaff}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Box mt={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Update Job"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <MuiAlert severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditJob;
