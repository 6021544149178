import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import axios from "axios";
import { environment } from "../../environment";
import { jwtDecode } from "jwt-decode";
import { formatDate, formatDateTime } from '../../Utils/DateUtils';  // Ensure this is imported

function ViewJob() {
  const getUserRoleFromToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        return decodedToken.role; // Adjust according to your token structure
      } catch (error) {
        console.error("Error decoding token:", error);
        return null;
      }
    }
    return null;
  };

  const userRole = getUserRoleFromToken();
  const hideField = userRole === "staff";
  const fieldsToHide = hideField
    ? ["orgNoOfhours", "orgHourRate", "orgTotal"]
    : [];

  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [jobType, setJobType] = useState(""); // To differentiate Client and Staff jobs
  const [error, setError] = useState("");

  const fieldNames = {
    jobName: "Job Name",
    description: "Description",
    orgNoOfhours: "Original Number of Hours",
    orgHourRate: "Original Hour Rate",
    orgTotal: "Original Total",
    staffPayTotal: "Staff Pay Total",
    estNoOfhours: "Estimated Number of Hours",
    staffHourRate: "Staff Hour Rate",
    staffExtraHours: "Staff Extra Hours",
    staffExtraPayment: "Staff Extra Payment",
    jobStatus: "Job Status",
    client: "Client",
    assignedStaff: "Assigned Staff",
    jobDate: "Start Time",
    signInTime: "Sign In Time",
    signOffTime: "Sign Off Time",
  };

  useEffect(() => {
    if (id) {
      fetchJobDetails();
    } else {
      setError("Job ID is missing. Unable to fetch job details.");
    }
  }, [id]);

  const fetchJobDetails = async () => {
    try {
      const response = await axios.get(
        `${environment.apiUrl}/job/getJobById/${id}`
      );
      const responseData = response.data;
  
      if (responseData.success) {
        const { job } = responseData;
  
        // Transform job object to exclude unwanted fields
        const transformedJob = {
          ...job,
          client: {
            ...job.client,
          },
        };
        if (job.assignedStaff) {
          transformedJob.assignedStaff = { ...job.assignedStaff };
        }
  
        delete transformedJob._id; // Remove _id
        delete transformedJob.__v; // Remove __v
        delete transformedJob.mainJob;
        delete transformedJob.updatedAt;
        delete transformedJob.createdAt;
  
        setJobDetails(transformedJob);
      } else {
        setError("Failed to fetch job details.");
      }
    } catch (error) {
      setError("Error fetching job details.");
      console.error(error);
    }
  };

  if (error) {
    return (
      <Box m="20px">
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!jobDetails) {
    return (
      <Box m="20px">
        <Typography variant="h6">Loading job details...</Typography>
      </Box>
    );
  }

  return (
    <Box m="20px" height="85vh" overflow="auto" paddingRight="20px">
      <Header title={`View Job ID: ${id}`} subtitle={`Type: ${jobType}`} />
      <Box ml={"40px"}>
        <Grid container spacing={2}>
          {Object.entries(jobDetails)
            .filter(
              ([field]) =>
                !fieldsToHide.includes(field) &&
                field !== "client" &&
                field !== "assignedStaff" &&
                field !== "type" // Exclude type field from display
            )
            .map(([field, value]) => (
              <React.Fragment key={field}>
                <Grid item xs={1.8}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    {fieldNames[field] || field}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h5" component="span" fontWeight="bold">
                    :
                  </Typography>
                </Grid>
                <Grid item xs={9.2}>
                  <Typography>
                    {/* Format the dates */}
                    {field === "jobDate" ||
                    field === "signInTime" ||
                    field === "signOffTime" ||
                    field === "jobStartDate"
                      ? formatDateTime(value)  // Apply formatDate to jobDate, signInTime, signOffTime
                      : typeof value === "string"
                      ? value
                      : JSON.stringify(value)}
                  </Typography>
                </Grid>
              </React.Fragment>
            ))}

          {/* Client Details */}
          {jobDetails.client && (
            <>
              <Grid item xs={1.8}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  {fieldNames.client}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  :
                </Typography>
              </Grid>
              <Grid item xs={9.2}>
                <Typography>{`${jobDetails.client.firstName} ${jobDetails.client.lastName}`}</Typography>
                <Typography>{`Phone Number: ${jobDetails.client.phoneNumber}`}</Typography>
                <Typography>{`Address: ${jobDetails.client.address}`}</Typography>
              </Grid>
            </>
          )}

          {/* Assigned Staff Details */}
          {jobDetails.assignedStaff && (
            <>
              <Grid item xs={1.8}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  {fieldNames.assignedStaff}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h5" component="span" fontWeight="bold">
                  :
                </Typography>
              </Grid>
              <Grid item xs={9.2}>
                <Typography>{`${jobDetails.assignedStaff.firstName} ${jobDetails.assignedStaff.lastName}`}</Typography>
                <Typography>{`Phone Number: ${jobDetails.assignedStaff.phoneNumber}`}</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default ViewJob;
