import {
  Box,
  Button,
  MenuItem,
  Alert as MuiAlert,
  Select,
  Snackbar,
  TextField,
  Typography,
  FormControl,
  AlertTitle,
  FormHelperText,
  IconButton,
  Tooltip,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import axios from "axios";
import Header from "../../components/Header";
import InfoIcon from "@mui/icons-material/Info";
import { environment } from "../../environment";
import { JobTypes } from "../../enum";
import AddClientJobDialog from "../../components/AddClientJobDialog";
import AddStaffJobDialog from "../../components/AddStaffJobDialog";
import EditClientJobDialog from "../../components/EditClientJobDialog";
import EditStaffJobDialog from "../../components/EditStaffJobDialog";
import ReusableTable from "../../components/ReusableTable";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { formatDate, formatDateTime } from "../../Utils/DateUtils";

const AddJob = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const [isClientJobDialog, setIsClientJobDialog] = useState(false);
  const [isStaffJobDialog, setIsStaffJobDialog] = useState(false);
  const [clientJobs, setClientJobs] = useState([]);
  const [staffJobs, setStaffJobs] = useState([]);

  const [selectedJob, setSelectedJob] = useState(null);
  const [isEditClientJobDialog, setIsEditClientJobDialog] = useState(false);
  const [isEditStaffJobDialog, setIsEditStaffJobDialog] = useState(false);

  const handleDeleteClientJob = (id) => {
    setClientJobs((prevJobs) => prevJobs.filter((job) => job.id !== id));
  };

  const handleDeleteStaffJob = (id) => {
    setStaffJobs((prevJobs) => prevJobs.filter((job) => job.id !== id));
  };

  const handleSaveClientJob = (updatedJob) => {
    const updatedJobs = clientJobs.map((job) =>
      job.id === updatedJob.id ? { ...job, ...updatedJob } : job
    );

    setClientJobs(updatedJobs);
    setIsEditClientJobDialog(false); // Close the dialog
    setSelectedJob(null); // Reset selected job
  };

  const handleSaveStaffJob = (updatedJob) => {
    const updatedJobs = staffJobs.map((job) =>
      job.id === updatedJob.id ? { ...job, ...updatedJob } : job
    );

    setStaffJobs(updatedJobs);
    setIsEditStaffJobDialog(false); // Close the dialog
    setSelectedJob(null); // Reset selected job
  };

  const clientColumns = [
    { field: "jobName", headerName: "Job Name", flex: 1 },
    { field: "clientName", headerName: "Client", flex: 1 },
    {
      field: "jobStartDate",
      headerName: "Start Time",
      flex: 1,
      renderCell: (params) => formatDateTime(params.value),
    },
    { field: "orgNoOfhours", headerName: "Original Hours", flex: 1 },
    { field: "orgHourRate", headerName: "Hourly Rate", flex: 1 },
    { field: "orgTotal", headerName: "Total", flex: 1 },
    { field: "jobStatus", headerName: "Status", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => {
              setSelectedJob(params.row);
              setIsEditClientJobDialog(true);
            }}
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteClientJob(params.row.id)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const staffColumns = [
    { field: "jobName", headerName: "Job Name", flex: 1 },
    { field: "staffName", headerName: "Staff Name", flex: 1 },
    { field: "clientName", headerName: "Client", flex: 1 },
    {
      field: "jobStartDate",
      headerName: "Start Date",
      flex: 1,
      renderCell: (params) => formatDateTime(params.value),
    },
    { field: "estNoOfhours", headerName: "Est. Hours", flex: 1 },
    { field: "staffHourRate", headerName: "Hourly Rate", flex: 1 },
    { field: "staffPayTotal", headerName: "Total Pay", flex: 1 },
    { field: "jobStatus", headerName: "Status", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => {
              setSelectedJob(params.row);
              setIsEditStaffJobDialog(true);
            }}
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteStaffJob(params.row.id)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const handleFormSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      values.staffJobs = staffJobs;
      values.clientJobs = clientJobs;

      const timezoneOffset = new Date().getTimezoneOffset();
      values.timezoneOffset = timezoneOffset;

      const response = await axios.post(
        environment.apiUrl + `/super/addMainJob`,
        values,
        { headers }
      );
      console.log("Response:", response.data);

      if (response.status === 201) {
        setAlertSeverity("success");
        setAlertMessage("Job Added Successfully");
        setTimeout(() => {
          navigate("/superjobs");
        }, 2000);
        resetForm();
      } else {
        setAlertSeverity("error");
        setAlertMessage(response.data.result.message);
      }
    } catch (error) {
      console.error("Error creating job:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to add job");
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  return (
    <Box
      m="20px"
      height="85vh"
      width="98%"
      overflow="auto"
      paddingRight="20px"
      position="relative"
    >
      <Header title="Add Super Job" subtitle="" />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          sx={{ color: "#fff" }}
        >
          <AlertTitle>
            {alertSeverity === "success" ? "Success" : "Error"}
          </AlertTitle>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={jobSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} height="41vh">
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Typography fontWeight="bold" fontSize="16px">
                Job Name*
              </Typography>
              <Box mt={-3}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.jobName}
                  name="jobName"
                  error={!!touched.jobName && !!errors.jobName}
                  helperText={touched.jobName && errors.jobName}
                />
              </Box>
              <Typography fontWeight="bold" fontSize="16px">
                Description*
              </Typography>
              <Box mt={-3}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  multiline
                  rows={3}
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                />
              </Box>
              <Box display="flex" alignItems="center" gap={4} mt={-1}>
                {/* Type Field */}
                <Box flex={1}>
                  <Typography fontWeight="bold" fontSize="16px">
                    Type*
                    <Tooltip
                      title={
                        <span style={{ fontSize: '13px', lineHeight: '1.5' }}>
                          <strong>Once</strong> - Occurs only once.
                          <br />
                          <strong>Daily</strong> - Occurs on all 7 days of the week.
                          <br />
                          <strong>Weekdays</strong> - Occurs on weekdays from Monday to Friday.
                          <br />
                          <strong>Weekend</strong> - Occurs every Saturday and Sunday.
                          <br />
                          <strong>Weekly</strong> - Occurs on any selected days of the week.
                          <br />
                          <strong>Fortnight</strong> - Occurs on selected days of the week, but biweekly (once every two weeks).
                        </span>
                      }
                      arrow
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <FormControl
                    fullWidth
                    variant="filled"
                    error={!!touched.type && !!errors.type}
                  >
                    <Select
                      fullWidth
                      variant="filled"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.type}
                      name="type"
                      error={!!touched.type && !!errors.type}
                      sx={{ width: "100%" }}
                    >
                      {Object.values(JobTypes).map((option) => (
                        <MenuItem key={option} value={option}>
                          {option.charAt(0).toUpperCase() + option.slice(1)}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.type && errors.type}
                    </FormHelperText>
                  </FormControl>
                </Box>

                {/* Job Days Field */}
                <Box flex={1}>
                  <Typography fontWeight="bold" fontSize="16px">
                    Job Days
                  </Typography>
                  <FormControl
                    fullWidth
                    variant="filled"
                    error={!!touched.days && !!errors.days}
                  >
                    <Select
                      multiple // Enables multiple selection
                      fullWidth
                      variant="filled"
                      onBlur={handleBlur}
                      onChange={(event) =>
                        setFieldValue(
                          "days",
                          event.target.value // Handle multiple selections
                        )
                      }
                      value={values.days}
                      name="days"
                      error={!!touched.days && !!errors.days}
                      disabled={
                        values.type !== JobTypes.WEEKLY &&
                        values.type !== JobTypes.FORTNIGHT
                      } // Enable only for 'weekly' or 'fortnight'
                      renderValue={(selected) =>
                        selected && selected.length > 0
                          ? selected.join(", ")
                          : "Select days"
                      }
                      sx={{ width: "100%" }}
                    >
                      {[
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                      ].map((day) => (
                        <MenuItem key={day} value={day}>
                          {day}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.days && errors.days}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Box>

              <Box display="flex">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#6870fa",
                    color: "white",
                    marginRight: 2,
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#3e4396",
                    },
                  }}
                  onClick={() => setIsClientJobDialog(true)}
                >
                  Add Client Job
                </Button>
              </Box>

              <ReusableTable
                title=""
                data={clientJobs}
                columns={clientColumns}
                enableColumnsAndFilters={false}
              />

              <Box display="flex">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#6870fa",
                    color: "white",
                    marginRight: 2,
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#3e4396",
                    },
                  }}
                  onClick={() => setIsStaffJobDialog(true)}
                >
                  Add Staff Job
                </Button>
              </Box>

              <ReusableTable
                title=""
                data={staffJobs}
                columns={staffColumns}
                enableColumnsAndFilters={false}
              />

              <AddClientJobDialog
                open={isClientJobDialog}
                handleFormSubmit={null}
                handleClose={() => setIsClientJobDialog(false)}
                handleAddJob={(newJob) => {
                  setClientJobs((prevJobs) => [
                    ...prevJobs,
                    { id: clientJobs.length + 1, ...newJob },
                  ]); // Add job with unique ID
                  setIsClientJobDialog(false);
                }}
              />

              <AddStaffJobDialog
                open={isStaffJobDialog}
                handleFormSubmit={null}
                handleClose={() => setIsStaffJobDialog(false)}
                handleAddJob={(newJob) => {
                  setStaffJobs((prevJobs) => [
                    ...prevJobs,
                    { id: staffJobs.length + 1, ...newJob },
                  ]); // Add job with unique ID
                  setIsStaffJobDialog(false);
                }}
              />

              <EditClientJobDialog
                open={isEditClientJobDialog}
                onClose={() => setIsEditClientJobDialog(false)}
                job={selectedJob}
                setJobs={setClientJobs}
                onSave={handleSaveClientJob}
              />
              <EditStaffJobDialog
                open={isEditStaffJobDialog}
                onClose={() => setIsEditStaffJobDialog(false)}
                job={selectedJob}
                setJobs={setStaffJobs}
                onSave={handleSaveStaffJob}
              />
            </Box>

            {/* Submit Button */}
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{
                  backgroundColor: "#6870fa",
                  color: "white",
                  marginRight: 2,
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#3e4396",
                  },
                }}
              >
                {loading ? "Adding..." : "Create Super Job"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const jobSchema = yup.object().shape({
  jobName: yup.string().required("Job Name is required"),
  description: yup.string().required("Description is required"),
  type: yup.string().required("Type is required"),
  days: yup
    .array()
    .of(yup.string())
    .when("type", {
      is: (val) => val === JobTypes.WEEKLY && val === JobTypes.FORTNIGHT,
      then: yup.array().min(1, "At least one day must be selected"),
      otherwise: yup.array(),
    }),
});

const initialValues = {
  jobName: "",
  description: "",
  type: JobTypes.ONCE,
  days: [],
};

export default AddJob;
