import React from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  InputLabel,
} from "@mui/material";
import Header from "./Header";
import { AustralianStatesForHolidays } from "../enum.js";

const AddHolidayDialog = ({
  open,
  handleClose,
  handleAddHoliday,
  selectedDate,
  newHoliday,
  setNewHoliday,
}) => {
  const holidayStates = Object.values(AustralianStatesForHolidays); // Convert to an array

  const handleStateChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes("All")) {
      // If "All" is selected, keep only "All"
      setNewHoliday({ ...newHoliday, states: ["All"] });
    } else {
      // Otherwise, update the states normally, excluding "All"
      setNewHoliday({
        ...newHoliday,
        states: value.filter((state) => state !== "All"),
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{
        zIndex: 1000,
      }}
    >
      <DialogTitle>
        <Header title="Add a New Holiday" subtitle="" />
      </DialogTitle>

      <DialogContent>
        <Typography fontWeight="bold" fontSize="16px" marginBottom="8px">
          Date :
        </Typography>
        <TextField
          margin="dense"
          variant="filled"
          type="text"
          fullWidth
          value={selectedDate}
          InputProps={{
            readOnly: true, // Make the field read-only
            disableUnderline: true,
            sx: {
              fontWeight: "bold",
              fontSize: "1.1rem",
              color: "#444",
              backgroundColor: "#f0f0f0",
              "&:hover": {
                backgroundColor: "#f0f0f0", // Ensure no background change on hover
                cursor: "default", // Prevent pointer cursor
              },
              marginTop: "-10",
            },
          }}
          sx={{
            margin: "0", // Remove external margins
          }}
        />

        <Typography fontWeight="bold" fontSize="16px" marginTop="16px">
          Holiday Name (Optional) : 
        </Typography>
        <TextField
          margin="dense"
          variant="filled"
          type="text"
          fullWidth
          value={newHoliday.holidayName || ""}
          onChange={(e) =>
            setNewHoliday({ ...newHoliday, holidayName: e.target.value })
          }
        />

        <Typography fontWeight="bold" fontSize="16px" marginTop="16px">
          Applied State(s) : 
        </Typography>
        <FormControl fullWidth variant="filled" margin="dense">
          <InputLabel>Select State(s)</InputLabel>
          <Select
            multiple
            value={newHoliday.states || []}
            onChange={handleStateChange}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                  width: 250,
                },
              },
            }}
          >
            {holidayStates.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#888",
            color: "white",
            marginRight: 1,
            marginBottom: 2,
            fontSize: "16px",
            "&:hover": {
              backgroundColor: "#555",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleAddHoliday}
          variant="contained"
          sx={{
            backgroundColor: "#6870fa",
            color: "white",
            marginRight: 2,
            marginBottom: 2,
            fontSize: "16px",
            "&:hover": {
              backgroundColor: "#3e4396",
            },
          }}
        >
          Add Holiday
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddHolidayDialog;
