import {
  Box,
  Button,
  MenuItem,
  Snackbar,
  Select,
  TextField,
  Typography,
  FormControl,
  Alert as MuiAlert,
  AlertTitle,
  FormHelperText,
  IconButton,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import axios from "axios";
import Header from "../../components/Header";
import { environment } from "../../environment";
import { JobTypes } from "../../enum";
import EditClientJobDialog from "../../components/EditClientJobDialog";
import EditStaffJobDialog from "../../components/EditStaffJobDialog";
import ReusableTable from "../../components/ReusableTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { formatDate, formatDateTime } from '../../Utils/DateUtils';

const UpdateJob = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const [isClientJobDialog, setIsClientJobDialog] = useState(false);
  const [isStaffJobDialog, setIsStaffJobDialog] = useState(false);
  const [clientJobs, setClientJobs] = useState([]);
  const [staffJobs, setStaffJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobDetails, setJobDetails] = useState({
    jobName: "",
    description: "",
    type: "",
    days: [],
  });

  const { id } = useParams();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchJobDetails();
  }, [id]);

  const fetchJobDetails = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        environment.apiUrl + `/super/getTaskById/${id}`,
        { headers }
      );
      const data = response.data;

      if (response.status === 200) {
        setJobDetails(data.mainJob || {});

        // Fetch client names
        const clientJobsWithNames = await Promise.all(
          (data.clientJobs || []).map(async (job, index) => {
            const clientName = await fetchClientName(job.client);
            return {
              ...job,
              id: job.id || index + 1, // Ensure unique id
              clientName,
            };
          })
        );

        setClientJobs(clientJobsWithNames);

        // Fetch staff names
        const staffJobsWithNames = await Promise.all(
          (data.staffJobs || []).map(async (job, index) => {
            const staffName = await fetchStaffName(job.assignedStaff);
            const clientName = await fetchClientName(job.client);
            return {
              ...job,
              id: job.id || index + 1, // Ensure unique id
              staffName,
              clientName,
            };
          })
        );

        setStaffJobs(staffJobsWithNames);
      } else {
        setAlertSeverity("error");
        setAlertMessage("Failed to fetch job details.");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error fetching job details:", error);
      setAlertSeverity("error");
      setAlertMessage("Error fetching job details.");
      setOpenSnackbar(true);
    }
  };

  const fetchClientName = async (clientId) => {
    try {
      if (!clientId) return "Undefined"; // Handle missing clientId
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        environment.apiUrl + `/client/getClientById/${clientId}`,
        { headers }
      );

      if (response.status === 200) {
        const client = response.data.client;
        return `${client.firstName || "Undefined"} ${
          client.lastName || ""
        }`.trim();
      } else {
        console.error("Failed to fetch client name:", response.data.message);
        return "Undefined";
      }
    } catch (error) {
      console.error("Error fetching client name:", error);
      return "Undefined";
    }
  };

  const fetchStaffName = async (staffId) => {
    try {
      if (!staffId) return "Undefined"; // Handle missing staffId
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        environment.apiUrl + `/staff/getStaffById/${staffId}`,
        { headers }
      );

      if (response.status === 200) {
        const staff = response.data.staff;
        return `${staff.firstName || "Undefined"} ${
          staff.lastName || ""
        }`.trim();
      } else {
        console.error("Failed to fetch staff name:", response.data.message);
        return "Undefined";
      }
    } catch (error) {
      console.error("Error fetching staff name:", error);
      return "Undefined";
    }
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const payload = {
        ...values,
        clientJobs,
        staffJobs,
      };

      const response = await axios.put(
        environment.apiUrl + `/super/updateSuperTask/${id}`,
        payload,
        { headers }
      );

      if (response.status === 200) {
        setAlertSeverity("success");
        setAlertMessage("Job updated successfully");
        setTimeout(() => {
          navigate("/superjobs");
        }, 2000);
      } else {
        setAlertSeverity("error");
        setAlertMessage(response.data.message || "Failed to update job.");
      }
    } catch (error) {
      console.error("Error updating job:", error);
      setAlertSeverity("error");
      setAlertMessage("Failed to update job.");
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  const handleDeleteClientJob = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this task!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        // Remove the task locally from the data array
        setClientJobs((prevData) => prevData.filter((item) => item.id !== id));

        Swal.fire("Deleted!", "The task has been deleted locally.", "success");
      }
    });
  };

  const handleDeleteStaffJob = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this task!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        // Remove the task locally from the data array
        setStaffJobs((prevData) => prevData.filter((item) => item.id !== id));

        Swal.fire("Deleted!", "The task has been deleted locally.", "success");
      }
    });
  };

  const handleSaveClientJob = (updatedJob) => {
    const updatedJobs = clientJobs.map((job) =>
      job.id === updatedJob.id ? { ...job, ...updatedJob } : job
    );

    setClientJobs(updatedJobs);
    setIsClientJobDialog(false); // Close the dialog
    setSelectedJob(null); // Reset selected job
  };

  const handleSaveStaffJob = (updatedJob) => {
    const updatedJobs = staffJobs.map((job) =>
      job.id === updatedJob.id ? { ...job, ...updatedJob } : job
    );

    setStaffJobs(updatedJobs);
    setIsStaffJobDialog(false); // Close the dialog
    setSelectedJob(null); // Reset selected job
  };

  const clientColumns = [
    { field: "jobName", headerName: "Job Name", flex: 1 },
    { field: "clientName", headerName: "Client", flex: 1 },
    { field: "jobStartDate", headerName: "Start Time", flex: 1, renderCell: (params) => formatDateTime(params.value), },
    { field: "orgNoOfhours", headerName: "Original Hours", flex: 1 },
    { field: "orgHourRate", headerName: "Hourly Rate", flex: 1 },
    { field: "orgTotal", headerName: "Total", flex: 1 },
    { field: "jobStatus", headerName: "Status", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => {
              setSelectedJob(params.row);
              setIsClientJobDialog(true);
            }}
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteClientJob(params.row.id)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const staffColumns = [
    { field: "jobName", headerName: "Job Name", flex: 1 },
    { field: "staffName", headerName: "Staff Name", flex: 1 },
    { field: "clientName", headerName: "Client", flex: 1 },
    { field: "jobStartDate", headerName: "Start Date", flex: 1, renderCell: (params) => formatDateTime(params.value), },
    { field: "estNoOfhours", headerName: "Est. Hours", flex: 1 },
    { field: "staffHourRate", headerName: "Hourly Rate", flex: 1 },
    { field: "staffPayTotal", headerName: "Total Pay", flex: 1 },
    { field: "jobStatus", headerName: "Status", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => {
              setSelectedJob(params.row);
              setIsStaffJobDialog(true);
            }}
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteStaffJob(params.row.id)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box m="20px" height="85vh" width="98%" overflow="auto" paddingRight="20px">
      <Header title="Update Super Job" subtitle="" />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          elevation={6}
          variant="filled"
          sx={{ color: "#fff" }}
        >
          <AlertTitle>
            {alertSeverity === "success" ? "Success" : "Error"}
          </AlertTitle>
          {alertMessage}
        </MuiAlert>
      </Snackbar>
      <Formik
        initialValues={jobDetails}
        onSubmit={handleFormSubmit}
        enableReinitialize
        validationSchema={jobSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns={
                isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
              }
            >
              <Typography fontWeight="bold" fontSize="16px">
                Job Name*
              </Typography>
              <Box mt={-3}>
                <TextField
                  name="jobName"
                  fullWidth
                  variant="filled"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.jobName}
                  error={touched.jobName && Boolean(errors.jobName)}
                  helperText={touched.jobName && errors.jobName}
                />
              </Box>
              <Typography fontWeight="bold" fontSize="16px">
                Description*
              </Typography>
              <Box mt={-3}>
                <TextField
                  name="description"
                  fullWidth
                  variant="filled"
                  multiline
                  rows={3}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  error={touched.description && Boolean(errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Box>
              <Box display="flex" alignItems="center" gap={4} mt={-1}>
                <Box flex={1}>
                  <Typography fontWeight="bold" fontSize="16px">
                    Type*
                  </Typography>
                  <FormControl fullWidth variant="filled">
                    <Select
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      error={touched.type && Boolean(errors.type)}
                    >
                      {Object.values(JobTypes).map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.type && errors.type}
                    </FormHelperText>
                  </FormControl>
                </Box>

                <Box flex={1}>
                  <Typography fontWeight="bold" fontSize="16px">
                    Job Days
                  </Typography>
                  <FormControl
                    fullWidth
                    variant="filled"
                    error={!!touched.days && !!errors.days}
                  >
                    <Select
                      multiple // Enables multiple selection
                      fullWidth
                      variant="filled"
                      onBlur={handleBlur}
                      onChange={(event) =>
                        setFieldValue(
                          "days",
                          event.target.value // Handle multiple selections
                        )
                      }
                      value={values.days}
                      name="days"
                      error={!!touched.days && !!Boolean(errors.days)}
                      disabled={
                        values.type !== JobTypes.WEEKLY &&
                        values.type !== JobTypes.FORTNIGHT
                      } // Enable only for 'weekly' or 'fortnight'
                      renderValue={(selected) =>
                        selected && selected.length > 0
                          ? selected.join(", ")
                          : "Select days"
                      }
                      sx={{ width: "100%" }}
                    >
                      {[
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                      ].map((day) => (
                        <MenuItem key={day} value={day}>
                          {day}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {touched.days && errors.days}
                    </FormHelperText>
                  </FormControl>
                </Box>
              </Box>

              <ReusableTable
                title="Client Jobs"
                data={clientJobs}
                columns={clientColumns}
                enableColumnsAndFilters={false}
              />
              <ReusableTable
                title="Staff Jobs"
                data={staffJobs}
                columns={staffColumns}
                enableColumnsAndFilters={false}
              />

              <EditClientJobDialog
                open={isClientJobDialog}
                onClose={() => setIsClientJobDialog(false)}
                job={selectedJob}
                setJobs={setClientJobs}
                onSave={handleSaveClientJob}
              />
              <EditStaffJobDialog
                open={isStaffJobDialog}
                onClose={() => setIsStaffJobDialog(false)}
                job={selectedJob}
                setJobs={setStaffJobs}
                onSave={handleSaveStaffJob}
              />

              <Box display="flex" justifyContent="end">
                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  sx={{
                    backgroundColor: "#6870fa",
                    color: "white",
                    marginRight: 2,
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#3e4396",
                    },
                  }}
                >
                  {loading ? "Updating..." : "Update Job"}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const jobSchema = yup.object().shape({
  jobName: yup.string().required("Job Name is required"),
  description: yup.string().required("Description is required"),
  type: yup.string().required("Type is required"),
  days: yup
    .array()
    .of(yup.string())
    .when("type", {
      is: (val) => val === JobTypes.WEEKLY && val === JobTypes.FORTNIGHT,
      then: yup.array().min(1, "At least one day must be selected"),
      otherwise: yup.array(),
    }),
});

export default UpdateJob;
