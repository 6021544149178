import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Header from "./Header";
import axios from "axios";
import { environment } from "../environment";
import Swal from "sweetalert2";

const EditNoteDialog = ({
  open,
  handleClose,
  handleAddNote,
  editNote,
  setEditNote,
}) => {
  const [clients, setClients] = useState([]);
  const [staffs, setStaffs] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (open) {
      fetchClients();
      fetchStaffs();
    }
  }, [open]);

  const fetchClients = async () => {
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(
        `${environment.apiUrl}/client/getAllActiveClient`,
        { headers }
      );
      if (response.data.success) {
        setClients(response.data.clients || []);
      } else {
        Swal.fire("Error", "Failed to fetch clients", "error");
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      Swal.fire("Error", "Failed to fetch clients", "error");
    }
  };

  const fetchStaffs = async () => {
    try {
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(
        `${environment.apiUrl}/staff/getAllActiveStaff`,
        { headers }
      );
      if (response.data.success) {
        setStaffs(response.data.staffs || []);
      } else {
        Swal.fire("Error", "Failed to fetch staff members", "error");
      }
    } catch (error) {
      console.error("Error fetching staffs:", error);
      Swal.fire("Error", "Failed to fetch staff members", "error");
    }
  };

  if (!editNote) return null; // Return null if editNote is not set

  const handleInputChange = (field, value) => {
    setEditNote((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{
        zIndex: 1000,
      }}
    >
      <DialogTitle>
        <Header title="Update Note" subtitle="" />
      </DialogTitle>

      <DialogContent>
        <Typography fontWeight="bold" fontSize="16px">
          Title*
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          variant="filled"
          type="text"
          fullWidth
          value={editNote.title}
          onChange={(e) => handleInputChange("title", e.target.value)}
          InputProps={{
            sx: {
              fontWeight: "bold",
              fontSize: "1.1rem",
            },
          }}
        />

        <Typography fontWeight="bold" fontSize="16px" mt={2}>
          Description*
        </Typography>
        <TextField
          margin="dense"
          variant="filled"
          type="text"
          fullWidth
          multiline
          rows={6}
          value={editNote.description}
          onChange={(e) => handleInputChange("description", e.target.value)}
        />

        <Typography fontWeight="bold" fontSize="16px" mt={2}>
          Client
        </Typography>
        <FormControl fullWidth variant="filled" margin="dense">
          <Select
            value={editNote.client || ""}
            onChange={(e) => handleInputChange("client", e.target.value)}
          >
            <MenuItem value="">None</MenuItem>
            {clients.map((client) => (
              <MenuItem key={client._id} value={client._id}>
                {client.firstName} {client.lastName}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Select a client (optional)</FormHelperText>
        </FormControl>

        <Typography fontWeight="bold" fontSize="16px" mt={2}>
          Staff
        </Typography>
        <FormControl fullWidth variant="filled" margin="dense">
          <Select
            value={editNote.staff || ""}
            onChange={(e) => handleInputChange("staff", e.target.value)}
          >
            <MenuItem value="">None</MenuItem>
            {staffs.map((staff) => (
              <MenuItem key={staff._id} value={staff._id}>
                {staff.firstName} {staff.lastName}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Select a staff member (optional)</FormHelperText>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "grey",
            color: "white",
            marginRight: 1,
            marginBottom: 2,
            fontSize: "16px",
            "&:hover": {
              backgroundColor: "darkgrey",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleAddNote}
          variant="contained"
          sx={{
            backgroundColor: "#6870fa",
            color: "white",
            marginRight: 2,
            marginBottom: 2,
            fontSize: "16px",
            "&:hover": {
              backgroundColor: "#3e4396",
            },
          }}
        >
          Update Note
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditNoteDialog;
